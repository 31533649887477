import "babel-polyfill";

export default class Item {
	constructor(props, index, args){
		this.language = args.lang
		this.seeMore = args.seeMore
		this.index = index
		this.props = props
	}

	_getUrlExtension( url ) {
		if (!url.source){
			return ''
		} 
		let format = url.source.split(/\#|\?/)[0].split('.').pop().trim();
		const images = ['jpg', 'jpeg', 'png']
		const videos = ['mp4', 'mov', 'ogg']
		if(images.includes(format)){
			return this._getImage(url)
		}else if(videos.includes(format)){
			return this._getVideo(url)
		}else{
			return ''
		}
	}

	_getVideo(data){
		return `<video width="100%" controls>
		  <source src="${data.source}" type="video/mp4">
		  Your browser does not support HTML5 video.
		</video>`
	}

	_getImage(data){
		return `<img src="${data.source}" height="${data.height}" width="${data.width}" />`
	}

	_getLink(data){
		return `<a href="${data}" target="_blank" rel="nofollow">${this.seeMore}</a>`
	}

	_getDate(data){
		let strDate = data.split('T')[0]
		let date = new Date(strDate)
		if(this._isValidDate(date)){
			let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			return date.toLocaleDateString(this.language, options)
		}
		return ''
	}

	_isValidDate(d) {
	  return d instanceof Date && !isNaN(d);
	}

	_getPhotoHeader(data){
		if(data.picture)
			return `<img class="media-object" src="${data.picture}">`
		return ''
	}

	_getPictureName(data){
		var re = /(?![^<]*>|[^<>]*<\/(?!(?:p|pre)>))((https?:)\/\/[a-z0-9&#=.\/\-?_]+)/gi;
		if (data){
			var checkUrl = re.exec(data)
			var subst = '<a href="$1" target="_blank" rel="nofollow">$1</a>'; 
			var result = data.replace(re, subst);
			return `<p>${result}</p>`

		}
		return ''
	}

	render(){
		let media = this._getUrlExtension(this.props.photo)
		let link = this._getLink(this.props.link)
		let date = this._getDate(this.props.created_time)
		let name = this._getPictureName(this.props.name)
		let photoHeader = this._getPhotoHeader(this.props.autor)
		let template = `
			<li class="social-feed-element social-feed__item social-feed__item__count_${this.index}" id="${this.props.id}">
				<div class="content social-feed__description">
					<a class="pull-left social-feed__description-link" href="${this.props.pageLink ? this.props.pageLink : this.props.link}" target="_blank" rel="nofollow">
						${photoHeader}
					</a>
				</div>
				<div class="media-body">
		            <p>
		                <i class="ico___fa-${this.props.iconClass}"></i>
		                <span class="author-title">${this.props.autor ? this.props.autor.name : ''}</span>
		                <span class="muted pull-right">${date}</span>
		            </p>
		            <div class="text-wrapper">
		                <p class="social-feed-text">${name} ${link}</p>
		            </div>
		        </div>
		        <div class="bg-fix social-feed__image">
			        <a class="social-feed__image-link" href="${this.props.link}" target="_blank">
			            ${media}
			        </a>
			    </div>
			</li>
		`
		return template
	}
}