import Item from './photo_item'
import FechaData from '../utils/fetch_data'
import "babel-polyfill";


export default class ListItems {
	constructor(props, lang, args){
		this.language = lang
		this.props = props
		this.limit = args.limit
		this.seeMore = args.seeMore
		this.sizeImageDefault = args.sizeImageDefault
	}

	_populate(){
		let elements = []
		if(this.props.hasOwnProperty('photos')){
			let photos = this.props.photos
			if (this.limit) photos = photos.slice(0, this.limit)
			photos.forEach((photo, index)=>{
				if (!photo.photo.width && !photo.photo.height && this.sizeImageDefault) {
					let size = this.sizeImageDefault.split('x')
					photo.photo.height = size[0]
					photo.photo.width = size[1]
				}
				let args = {
					'language': this.language,
					'seeMore': this.seeMore
				}
				let item = new Item(photo, index, args)
				elements.push(item)
			})
		}
		return elements
	}

	render(){
		let items = this._populate()
		let ul = document.createElement('ul')
		let liItems = ''
		ul.classList.add('social-feed-container', 'social-feed__list')
		items.forEach((item, i)=>{
			liItems += item.render()
		})
		ul.innerHTML = liItems
		return ul
	}

}