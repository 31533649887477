import "babel-polyfill";
import es6promise from 'es6-promise'; es6promise.polyfill();
import ListItems from './components/list_item'
import FechaData from './utils/fetch_data'


class SocialWall {
	constructor(props){
		this.container = props.container.startsWith('#') ? document.getElementById(props.container.replace('#', '')) : document.querySelector(props.container)
		this.language = props.lang
		this.limit = props.limit
		this.seeMore = props.see_more
		this.sizeImageDefault = props.size_image_default
		this.hotelId = props.hotel_id
		this.options = props.options || {}
		Object.entries(this.options).forEach((item) => {
			let url_data = (this.hotelId) ? `${item[1]}?hotel_id=${this.hotelId}`:item[1]
			this._fetch(url_data).then(data =>{
				this.render(data)
			})
		})
	}

	async _fetch(url){
		try {
			let fetch = new FechaData()
			let result = fetch.getData(url)
			return result
		}catch(error){
			console.log(error)
		}
	}

	render(data){
		let args = {
			'limit': this.limit,
			'seeMore': this.seeMore,
			'sizeImageDefault': this.sizeImageDefault
		}
		let list = new ListItems(data, this.language, args)
		if (Object.keys(data).length) this.container.appendChild(list.render())
		else this.container.style.display = 'none'
	}
}
window.SocialWall = SocialWall
