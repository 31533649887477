require('es6-promise').polyfill();
import "babel-polyfill";
import Axios from 'axios'

export default class FechaData {
	constructor(){
		
	}

	async getData(uri){
		const response = await Axios.get(uri)
		return response.data ? response.data : {'photos': []}
	}
}